import { SyntheticEvent, useCallback } from "react";
import { api } from "../../api/DataApi";
import { Section, Form, InputBlock, Label, Input, Button } from "./styles";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addUser } from "../../reducers/userReducer";

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const submit = useCallback(
    async (e: SyntheticEvent) => {
      e.preventDefault();
      const target = e.target as typeof e.target & {
        login: { value: string };
        password: { value: string };
      };
      const res = await api.login({ password: target.password.value, login: target.login.value }).catch(() => {});
      if (res) {
        dispatch(addUser(res));
        navigate("/");
      }
    },
    [dispatch, navigate]
  );
  return (
    <Section>
      <Form onSubmit={submit}>
        <InputBlock>
          <Label>Enter your login</Label>
          <Input type="text" name="login"></Input>
        </InputBlock>
        <InputBlock>
          <Label>Enter your password</Label>
          <Input type="password" name="password"></Input>
        </InputBlock>
        <Button type="submit">Login</Button>
      </Form>
    </Section>
  );
}

export default Login;
