import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import rootReducer from "./rootReducer";

export type RootState = ReturnType<typeof store.getState>;
export type ExtendedRootState = RootState;

export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

const store = configureStore({
  reducer: rootReducer,
  middleware: [],
  enhancers: [],
});

export const configureAppStore = () => store;

