import { Word } from "../../../models/Word";
import { config } from "../../../services/config.service";

const ImaginaryAPI = () => {
  const apiUrl = config.imaginaryApiUrl;

  const addNewWord = async (word: Word): Promise<Word> => {
    const res = await fetch(`${apiUrl}/words/create`, {
      method: "POST",
      body: JSON.stringify(word),
      headers: {
        "Content-Type": "application/json",
      },
    });
    return (await res.json()) as Word;
  };

  const getWords = async () => {
    const res = await fetch(`${apiUrl}/words`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const words = (await res.json()) as Word[];
    return words.reverse();
  };

  const deleteWord = async (word: Word) => {
    await fetch(`${apiUrl}/words/delete`, {
      method: "DELETE",
      body: JSON.stringify(word),
      headers: {
        "Content-Type": "application/json",
      },
    });
    return true;
  };

  const generate = async (lockState: { [type: string]: { value: string; locked: boolean } }) => {
    const queryParams = Object.keys(lockState).map((k) => `${k}=${lockState[k].locked}`);
    const res = await fetch(`${apiUrl}/words/generate?${queryParams.join("&")}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return await res.json();
  };

  return {
    addNewWord,
    generate,
    getWords,
    deleteWord,
  };
};

export const imaginaryApi = ImaginaryAPI();
