import styled from "@emotion/styled";
import { useCallback, useEffect, useState } from "react";
import { Word } from "../../../../models/Word";
import { imaginaryApi } from "../../api/ImaginaryApi";
import GeneratedWord from "./GeneratedWord";

const Words = styled.div`
  display: flex;
  flex-direction: column;
`;

const Generate = () => {
  const [currentWords, setCurrentWords] = useState<Array<Word & { locked: boolean }>>([]);
  const [wordLockState, setWordLockState] = useState({
    noun: { value: "", locked: false },
    pronoun: { value: "", locked: false },
    verb: { value: "", locked: false },
  });

  const generate = useCallback(async () => {
    setCurrentWords([
      { type: 'noun', value: ' ', locked: false },
      { type: 'pronoun', value: ' ', locked: false },
      { type: 'verb', value: ' ', locked: false },
    ]);
    const result: Word[] = await imaginaryApi.generate(wordLockState);

    setCurrentWords([
      {
        type: "noun",
        value: wordLockState.noun.locked
          ? wordLockState.noun.value
          : result.find((r) => r.type === "noun")?.value || "",
        locked: wordLockState.noun.locked,
      },
      {
        type: "pronoun",
        value: wordLockState.pronoun.locked
          ? wordLockState.pronoun.value
          : result.find((r) => r.type === "pronoun")?.value || "",
        locked: wordLockState.pronoun.locked,
      },
      {
        type: "verb",
        value: wordLockState.verb.locked
          ? wordLockState.verb.value
          : result.find((r) => r.type === "verb")?.value || "",
        locked: wordLockState.verb.locked,
      },
    ]);
  }, [wordLockState]);

  useEffect(() => {
    window.addEventListener("generate", generate);

    return () => {
      window.removeEventListener("generate", generate);
    };
  }, [generate]);

  return currentWords.length ? (
    <Words>
      {currentWords.map((w) => (
        <GeneratedWord
          key={w.type}
          word={w }
          onWordChange={(d) => setWordLockState((w: any) => ({ ...w, [d.type]: { value: d.value, locked: d.locked } }))}
        />
      ))}
    </Words>
  ) : null;
};

export default Generate;
