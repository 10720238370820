import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { CommonButtonStyle } from "../../styles/common";
import Generate from "../../app/imaginary/pages/generate/Generate";
import bg from "../../assets/images/bg.png";
import title from "../../assets/images/name.png";
import generate from "../../assets/images/generate.png";
import add from "../../assets/images/add.png";
import stars1 from "../../assets/images/stars1.png";
import stars2 from "../../assets/images/stars2.png";

const AppWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: url(${bg});
  background-size: cover;
  overflow: hidden;
  position: relative;
`;

const StartBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

const Button = styled.button`
  ${CommonButtonStyle}
`;

const GenerateImage = styled.img`
  width: 100%;
`;

const Navigate = styled(Link)`
  ${CommonButtonStyle}
`;

const Title = styled.img`
  background-size: cover;
  background-repeat: no-repeat;
  width: 65%;
  max-width: 50rem;
  min-width: 20rem;
  height: auto;
  margin-bottom: 2rem;
  z-index: 3;
  pointer-events: none;


  @media (max-width: 1280px) {
    width: 40%;
  }
`;

const Content = styled.div`
  /* position: relative;
  top: 100px; */
  z-index: 3;
`;

const Stars1 = styled.div`
  position: absolute;
  z-index: 1;
  width: 100%;
  background-image: url(${stars1});
  background-size: contain;
  height: 100%;
`;
const Stars2 = styled.div`
  position: absolute;
  z-index: 2;
  width: 100%;
  background-image: url(${stars2});
  background-size: contain;
  height: 100%;
  @media (max-width: 1425px) {
    display: none;
  }
`;

const Imaginary = () => {
  const generateImaginary = () => {
    window.dispatchEvent(new CustomEvent("generate"));
  };
  return (
    <AppWrapper>
      <Title src={title} />
      <Content>
        <Generate />
        <StartBlock>
          <Button onClick={generateImaginary}>
            <GenerateImage src={generate} />
          </Button>
          {/* <Navigate to="/imaginary/manage">
            <GenerateImage src={add} />
          </Navigate> */}
          {/* <Navigate to="/upload">Upload your imaginary</Navigate> */}
        </StartBlock>
      </Content>
      <Stars1 />
      {/* <Stars2 /> */}
    </AppWrapper>
  );
};

export default Imaginary;
