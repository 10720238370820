import { SyntheticEvent, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { api } from "../../api/DataApi";
import { Section, Form, InputBlock, Label, Input, Button } from "./styles";

function SignUp() {
  const navigate = useNavigate();
  const submit = useCallback(
    async (e: SyntheticEvent) => {
      e.preventDefault();
      const target = e.target as typeof e.target & {
        email: { value: string };
        login: { value: string };
        password: { value: string };
        validatePassword: { value: string };
      };
      if (target.password.value === target.validatePassword.value) {
        const res = await api
          .signup({
            email: target.email.value,
            password: target.password.value,
            login: target.login.value,
          })
          .catch(() => {});
        if (res) {
          
          navigate("/login");
        }
      }
    },
    [navigate]
  );
  return (
    <Section>
      <Form autoComplete="off" onSubmit={submit}>
        <InputBlock>
          <Label>Enter your email</Label>
          <Input autoComplete="false" type="email" name="email"></Input>
        </InputBlock>
        <InputBlock>
          <Label>Enter your login</Label>
          <Input autoComplete="false" type="text" name="login"></Input>
        </InputBlock>
        <InputBlock>
          <Label>Enter your password</Label>
          <Input autoComplete="false" type="password" name="password"></Input>
        </InputBlock>
        <InputBlock>
          <Label>Confirm your password</Label>
          <Input autoComplete="false" type="password" name="validatePassword"></Input>
        </InputBlock>
        <Button type="submit">SignUp</Button>
      </Form>
    </Section>
  );
}

export default SignUp;
