import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { configureAppStore } from "./configureStore";
import { Provider } from "react-redux";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

const store = configureAppStore();

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

reportWebVitals();
