import { lazy } from "react";
import { Route } from "react-router-dom";

const Imaginary = lazy(() => import("./Imaginary"));
const Manage = lazy(() => import("./pages/manage/Manage"));
const Upload = lazy(() => import("./pages/upload/Upload"));

export const getImaginaryRoutes = () => {
    return [
        // <Route key="" path="/" element={<Imaginary />} />,
        <Route key="imaginary" path="imaginary" element={<Imaginary />} />,
        <Route key="manage" path="imaginary/manage" element={<Manage />} />,
        <Route key="upload" path="imaginary/upload" element={<Upload />} />
    ]
}
