import styled from "@emotion/styled";
import { FC, useEffect, useState } from "react";
import { Svg } from "../../../../components/SvgFactory";
import { Word } from "../../../../models/Word";
import { baseAnimation } from "../../../../styles/common";

interface GeneratedWordProps {
  word: Word & { locked: boolean };
  onWordChange: (word: Word & { locked: boolean }) => void;
}

const WordElement = styled.span`
  ${baseAnimation}
  animation-name: fadeInFromNone;
  animation-duration: 1.5s;
  animation-fill-mode: backwards;
  border-radius: 15px;
  padding: 5px;
  min-width: 10rem;
  width: 25rem;
  text-align: center;
  margin: 1rem;
  position: relative;

  @media (max-width: 564px) {
    width: 20rem;
  }
  @media (max-height: 760px) {
    width: 20rem;
  }
  @media (max-height: 640px) {
    width: 15rem;
  }
`;

const StateSvg = styled(Svg)<{ type: "noun" | "pronoun" | "verb" }>`
  width: 1.5rem;
  height: 1.3rem;
  @media (max-width: 564px) {
    top: 20px;
    width: 1.3rem;
    height: 1.1rem;
  }
  @media (max-height: 760px) {
    top: 20px;
    width: 1.3rem;
    height: 1.1rem;
  }
  @media (max-height: 640px) {
    top: 15px;
    width: 1.1rem;
    height: 0.95rem;
  }
`;

const SvgWrapper = styled.div`
  right: 25px;
  top: 25px;
  position: absolute;

  cursor: pointer;
  @media (max-width: 564px) {
    top: 20px;
  }
  @media (max-height: 760px) {
    top: 20px;
  }
  @media (max-height: 640px) {
    top: 15px;
  }
`;

const GenerateImage = styled.img`
  width: 100%;
`;

const CurrentText = styled.span<{ type: "noun" | "pronoun" | "verb" }>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  font-size: 2rem;
  top: -5px;
  font-family: "Blackberry";

  ${({ type }) => {
    if (type === "noun") {
      return "color: #cb92a8";
    }
    if (type === "pronoun") {
      return "color: #4884aa";
    }
    return "color: #f0ad7f";
  }};

  @media (max-width: 564px) {
    font-size: 1.5rem;
  }
  @media (max-height: 760px) {
    font-size: 1.5rem;
  }
  @media (max-height: 640px) {
    font-size: 1.2rem;
  }
`;

const GeneratedWord: FC<GeneratedWordProps> = ({ word, onWordChange }) => {
  const [currentWord, setCurrentWord] = useState<Word & { locked: boolean }>();

  useEffect(() => {
    setCurrentWord({
      ...word,
    });
  }, [word]);

  return currentWord ? (
    <WordElement key={currentWord.type}>
      {<CurrentText type={currentWord.type}>{currentWord.value}</CurrentText>}
      <SvgWrapper
        onClick={() => {
          setCurrentWord((w) => {
            const res = { ...w!, locked: !w!.locked };
            onWordChange(res);
            return res;
          });
        }}
      >
        <StateSvg name={currentWord.locked ? "lock" : "unlock"} type={currentWord.type} />
      </SvgWrapper>
      <GenerateImage src={require(`../../../../assets/images/${currentWord.type}.png`)} />
    </WordElement>
  ) : null;
};

export default GeneratedWord;
