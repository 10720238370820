import { User, UserLogin, UserSignUp } from "../app/imaginary/models/User";
import { config } from "../services/config.service";

const DataApiProxy = () => {
  const apiUrl = config.dataApiUrl;

  const signup = async (user: UserSignUp) => {
    const res = await fetch(`${apiUrl}/users/signup`, {
      method: "POST",
      body: JSON.stringify(user),
      headers: {
        "Content-Type": "application/json",
      },
    });
    return (await res.json()) as User;
  };

  const login = async (user: UserLogin) => {
    const res = await fetch(`${apiUrl}/users/login`, {
      method: "POST",
      body: JSON.stringify(user),
      headers: {
        "Content-Type": "application/json",
      },
    });
    return await res.text();
  };

  return {
    signup,
    login,
  };
};

export const api = DataApiProxy();
