import React from 'react';
import styled from '@emotion/styled';

import { ReactComponent as Lock } from '../assets/svg/lock.svg';
import { ReactComponent as Unlock } from '../assets/svg/unlock.svg';

const svgMap: { [index: string]: JSX.Element } = {
	'lock': <Lock />,
	'unlock': <Unlock />
}

const Container = styled.svg<SvgProps>`
	color: ${({ color }) => color};
`;

interface SvgProps {
	name: string;
    color?: string;
}

export const Svg: React.FC<SvgProps> = ({ name, ...props }) => (
	<Container {...props} name={name}>
		{svgMap[name]}
	</Container>
);