import styled from "@emotion/styled";

export const Section = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Form = styled.form`
  height: 25rem;
  width: 25rem;
  /* background-color: #67b3bd14; */
`;

export const InputBlock = styled.div`
  position: relative;
  width: 100%;
  padding: 0 2rem;
`;

export const Label = styled.label`
  position: absolute;
  top: -50%;
  left: 2rem;
  font-size: 12px;
`;

export const Input = styled.input`
  padding: 7px 14px;
  margin: 2rem 0;
  display: block;
  width: 100%;
  border-radius: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.5);
`;

export const Button = styled.button`
  
`;
