import { Action, createSlice, PayloadAction } from "@reduxjs/toolkit";
import jwtDecode from "jwt-decode";
import { RootState } from "../configureStore";
import { UserInfo } from "../app/imaginary/models/User";

interface UserState {
  user: UserInfo | null;
}

const initialState: UserState = {
  user: null,
};

export type UserActionType = Action & { payload: string };

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    addUser: (state, action: PayloadAction<string>) => {
      state.user = jwtDecode(action.payload);
    },
  },
})

export const { addUser } = userSlice.actions;

export const selectUser = (state: RootState) => state.user

export default userSlice.reducer;