import { css } from "@emotion/react";

export const CommonButtonStyle = css`
  transition: all 0.2s ease-in-out;
  border: none;
  border-radius: 1.5rem;
  text-decoration: none;
  color: black;
  box-shadow: none;
  margin: 1rem;
  padding: 0;
  width: 25rem;
  text-align: center;
  display: block;
  background: transparent;
  font-size: 1rem;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 0px 18px 17px rgb(216 179 134), inset 0px 0px 14px 35px rgb(216 179 134);
    border-radius: 40%;
  }
  &:active {
    background-color: rgb(197, 197, 197);
  }
  @media (max-width: 564px) {
    width: 20rem;
  }
  @media (max-height: 760px) {
    width: 20rem;
  }
  @media (max-height: 640px) {
    width: 15rem;
  }
`;

export const baseAnimation = css`
  @keyframes fadeInFromNone {
    0% {
      display: none;
      opacity: 0;
    }

    1% {
      display: block;
      opacity: 0;
    }

    100% {
      display: block;
      opacity: 1;
    }
  } ;
`;
