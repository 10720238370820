import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Suspense } from "react";
import "./App.css";
import Login from "./pages/auth/Login";
import SignUp from "./pages/auth/SignUp";
import { getImaginaryRoutes } from "./app/imaginary/ImaginaryRouting";
import Imaginary from "./app/imaginary/Imaginary";

function App() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Router>
        {/* <TopBar /> */}
        <Routes>
          <Route path="" element={<Imaginary />} />
          <Route path="login" element={<Login />} /> 
          <Route path="signup" element={<SignUp />} />
          {getImaginaryRoutes()}
        </Routes>
      </Router>
    </Suspense>
  );
}

export default App;
